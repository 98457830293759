<template>
  <div class="container">
    <div class="header flex-between-start">
      <div class="handle">
        <div class="title">发放记录</div>
      </div>
      <div class="search">
        <el-date-picker
          v-model="time"
          type="daterange"
          range-separator="至"
          start-placeholder="领取开始日期"
          end-placeholder="结束日期"
          value-format="YYYY-MM-DD"
        />
        <!-- <el-select v-model="status" placeholder="请选择使用状态" clearable>
                    <el-option v-for="item in useStatus" :key="item.value" :label="item.label" :value="item.value"></el-option>
                </el-select> -->
        <div class="wrap">
          <el-select v-model="sentApp" placeholder="请选择领取渠道" clearable>
            <el-option v-for="item in appList" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
          <el-input v-model="couponKey" type="text" placeholder="请输入优惠券名称 | 活动号" clearable></el-input>
          <el-input v-model="mobile" type="text" placeholder="请输入手机号" clearable></el-input>
          <el-button @click="pageEmit(1)">搜索</el-button>
        </div>
      </div>
    </div>

    <div class="statistics" v-permission="['发放统计']">
      <el-tag effect="plain" size="large"
        >优惠券发放数量：{{ statistics.total_sent_count }}，待使用：{{ statistics.total_unused_count }}</el-tag
      >
    </div>

    <!-- 列表 -->
    <el-table :data="tableData.items" v-loading="loading">
      <el-table-column prop="mobile" label="用户手机号" width="130"></el-table-column>
      <el-table-column label="优惠券名称">
        <template #default="scope">
          {{ scope.row.coupon_activity?.name }}
        </template>
      </el-table-column>
      <el-table-column prop="" label="优惠金额" width="110">
        <template #default="scope">
          {{ scope.row.coupon_activity?.minus }}
        </template>
      </el-table-column>
      <el-table-column prop="" label="领取渠道" width="200">
        <template #default="scope">
          {{ scope.row.app_info?.name }}
        </template>
      </el-table-column>
      <!-- <el-table-column prop="" label="使用状态"></el-table-column> -->
      <el-table-column prop="total_num" label="领取数量" width="100"></el-table-column>
      <el-table-column prop="trans_ts" label="领取时间" width="180"></el-table-column>
    </el-table>
    <!-- 分页 -->
    <page
      @page-event="pageEmit"
      :current-page="pageParam.currentPage"
      :total="tableData.total"
      :page-size="tableData.count"
    ></page>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue'
import commonHandle from '@/hooks/useCommon'
import permissionHandle from '@/hooks/usePermission'
import statisticsModel from '@/model/statistics'
import appModel from '@/model/application'
import { ElMessage } from 'element-plus/lib/components'
import util from '@/lin/util/util'
import { useStore } from 'vuex'

export default {
  setup() {
    const useStatus = ref([
      {
        label: '已过期',
        value: 1,
      },
      {
        label: '待使用',
        value: 2,
      },
      {
        label: '已使用',
        value: 3,
      },
    ])
    const appList = ref([])
    const statistics = ref({})
    const time = ref([])
    const status = ref()
    const sentApp = ref()
    const mobile = ref()
    const couponKey = ref()
    const store = useStore()

    // 获取列表
    const getData = async pageQuery => {
      if (mobile.value && !util.checkLength(mobile.value, [10, 11])) return ElMessage.warning('请输入完整的手机号码')
      if (couponKey.value && !util.checkLength(couponKey.value, 2)) return ElMessage.warning('优惠券名称或活动号至少输入2个字符')
      if (checkPermission('发放统计')) getStatistics()

      loading.value = true
      const res = await statisticsModel.getSendRecord({
        page: pageQuery?.page || pageParam.currentPage - 1,
        count: pageQuery?.count || pageParam.count,
        start: time.value ? time.value[0] : null,
        end: time.value ? time.value[1] : null,
        // status: status.value,
        sent_app: sentApp.value,
        mobile: mobile.value,
        coupon_key: couponKey.value,
      })
      loading.value = false
      tableData.value = res
      return res
    }

    // 获取统计数据
    const getStatistics = async () => {
      const res = await statisticsModel.sendStatistics({
        start: time.value ? time.value[0] : null,
        end: time.value ? time.value[1] : null,
        sent_app: sentApp.value,
        mobile: mobile.value,
        coupon_key: couponKey.value,
      })
      statistics.value = res
    }

    // 获取注册平台列表
    const getAppList = async () => {
      const res = await appModel.getApplicationListAll()
      appList.value = res
    }

    const { tableData, loading, pageParam, pageEmit } = commonHandle(getData)

    const { checkPermission } = permissionHandle(store)

    onMounted(() => {
      getAppList()
      getData()
    })

    return {
      useStatus,
      time,
      appList,
      status,
      sentApp,
      mobile,
      couponKey,
      tableData,
      statistics,
      loading,
      pageParam,
      pageEmit,
      getAppList,
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/style/realize/custom';
</style>
